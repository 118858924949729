/* XXL screen */
body {
  background-color: black;
}

main {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  background-position: center;
  /*background-size: auto;*/
  position: relative;
  color: white;
  text-align: center;
  background-color: black;
  /*linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow: hidden;
  transition: 0.5s ease-in;
}

#dateTimeHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 32vh;
  margin: 72px 0 0 0;
}

#dateHeading {
  margin: 72px 0 0 0;
  font-size: 22px;
  padding-bottom: 12px;
}

#hourHeading,
#logoHeading {
  font-size: 120px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s cubic-bezier(0.26, 0.1, 0.6, 0.93);
}

#hourHeading > #timeofDay {
  font-size: 60px;
  font-weight: 400;
}

h2#clockSubheader {
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
}

#timezones {
  transition: 0.4s cubic-bezier(0.26, 0.1, 0.6, 0.93);
}

#googleSearchWrapper {
  position: absolute;
  transition: 0.4s cubic-bezier(0.26, 0.1, 0.6, 0.93);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 45%;
}

#googleSearchWrapper input {
  background-color: rgba(255, 255, 255, 0.9);
  display: block;
  border-radius: 40px;
  min-width: 600px;
  height: 70px;
  padding: 0 24px 0 54px;
  font-size: 18px;
}

.inputIcon {
  color: rgba(100, 100, 100, 1);
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

#timezones h2 {
  font-weight: 500;
}

.timezone {
  text-align: left;
  margin-bottom: 12px;
}

.timezone h4 {
  display: flex;
  padding: 6px 0 0 18px;
  margin: 0;
  font-weight: 400;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.8);
}

.timezoneTimeInfo {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 25px;
  margin: 0 0 6px 0;
}

.timezoneButton {
  margin: 0 0 3px 0;
}

.timezoneButton span {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8) !important;
}

.timezoneButton:hover span {
  color: rgba(255, 255, 255, 1) !important;
}

.css-1nmdiq5-menu {
  position: relative !important;
}

.react-virtualized-list-wrapper li {
  display: block;
}

.custom-option {
  display: block;
  padding: 0 !important;
}

.optionRow {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  height: 40px;
}

.optionRow:hover {
  transition-delay: 60ms;
  transition: 60ms;
  background: #deebff;
}

.optionCityName {
  width: 70%;
  padding: 8px 12px;
}

.optionTimeInfo {
  color: rgba(200, 200, 200, 1);
  width: 30%;
  padding: 8px 12px 8px 0;
  text-align: right;
}

#addTimezoneButton {
  font-size: 16px;
  border-radius: 22px;
  padding: 3px 12px;
  line-height: 28px;
  margin: 24px 0 0 0;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: rgba(0, 0, 0, 0.8);
}

#addTimezoneButton:hover {
  border: 1px solid rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(0, 0, 0, 1);
  transition: 0.2s;
}

.dateInfo {
  color: rgba(255, 255, 255, 0.9);
}

.trashIcon {
  height: 27px !important;
}

.trashIcon span {
  color: rgba(255, 255, 255, 0.8) !important;
  width: 20px;
}

.trashIcon span:hover {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 400;
  transition: 0.2s;
}

.timezoneButton img {
  padding: 0 3px 0 0;
}

#weekCalendar {
  display: flex;
  margin-left: auto;
  margin-bottom: -5px;
  width: 200px;
  text-align: right;
  justify-content: flex-end;
}

.weekDay {
  display: block;
  padding: 0 6px 0 0;
  text-align: center;
  transition: 0.4s cubic-bezier(0.26, 0.1, 0.6, 0.93);
}

.weekDay span {
  color: rgba(150, 150, 150, 0.8);
  font-size: 16px;
}

.weekDay:hover span {
  color: rgba(255, 255, 255, 1);
  transition: 0.2s;
}

button:disabled span {
  color: rgba(255, 255, 255, 0.7) !important;
}

#highlighterBorder {
  border: 2px dashed rgba(200, 200, 200, 0.9);
  position: absolute;
  width: 40px;
  transition: all 0.4s cubic-bezier(0.26, 0.1, 0.6, 0.93);
}

.calendarDay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  text-align: center;
}

.dayCell {
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 60px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.dayCell:first-child {
  border-radius: 22px 0 0 22px;
  border-left: 1px solid white;
}

.dayCell:last-child {
  border-right: 1px solid white;
  border-radius: 0 6px 6px 0;
}

.endOfDay {
  border-radius: 0 22px 22px 0;
  border-right: 1px solid white;
}

.dayCell h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  padding: 11px 0 0 0;
  margin: 0;
}

.dayCell div {
  font-size: 12px;
  margin-top: 1px;
}

.lateEarlyHour {
  background-color: rgba(232, 232, 232, 0.4);
}

.nonWorkHour {
  background-color: rgba(112, 112, 112, 0.6);
}

.calendarCell {
  background-color: rgba(157, 157, 157, 0.8);
  width: 40px;
  height: 60px;
  border: 1px solid white;
  border-right: 0;
  border-radius: 22px 0 0 22px !important;
  width: 37px;
  margin-left: 3px;
}

.calendarCell h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  padding: 3px 0 0px 0;
  margin: 0;
}

.calendarCell div {
  margin: 12px 0 0 0;
  font-size: 12px;
}

#footer {
  margin: 0 0 18px 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#footerToggle {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 12px 0 12px 0;
  margin: 0 auto 0 auto;
}

#footerToggle button {
  color: rgba(255, 255, 255, 0.6);
  background-color: transparent;
  border: 0px;
  line-height: 24px;
  display: block;
}

#footerToggle button:first-child {
  margin: 0 12px 0 0;
  line-height: 24px;
}

#footerToggle button:last-child {
  margin: 0 0 0 12px;
  line-height: 24px;
}

#footerToggle a:last-child {
  margin: 0 0 0 12px;
  line-height: 24px;
}

#footerLogo {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  flex-direction: row;
  bottom: 0;
  right: 0;
  width: 300px;
}

#footerLogo #timeGenieLogo {
  cursor: pointer;
  opacity: 0.8;
  width: 150px;
  padding: 0 24px 7px 0;
}

#miniTimezones {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 6px auto 48px auto;
  transition: 0.2s cubic-bezier(0.26, 0.1, 0.6, 0.93);
}

.miniTimezone {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-width: 220px;
  padding: 0 0 12px 0;
  font-size: 16px;
}

.miniTimezoneFlag {
  padding: 0 9px 0 0;
}

.miniTimezoneCity {
  align-self: stretch;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  padding: 0 0 0 3px;
}

.miniTimezoneTime {
  color: rgba(255, 255, 255, 1);
  margin-left: auto;
  font-weight: 200;
}

.miniTimezone span {
  font-size: 13px;
}

/* XL screen */
@media only screen and (max-width: 1200px) {
  main {
    overflow: hidden;
  }

  #dateHeading {
    font-size: 18px;
  }

  #hourHeading,
  #logoHeading {
    font-size: 80px;
  }

  h2#clockSubheader {
    font-size: 18px;
  }

  #googleSearchWrapper input {
    height: 50px;
    font-size: 16px;
  }

  .inputIcon {
    color: rgba(100, 100, 100, 1);
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
  }

  .timezoneTimeInfo {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 25px;
    margin: 0 0 6px 0;
  }

  .timezoneButton span {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8) !important;
  }

  #addTimezoneButton {
    font-size: 12px;
    line-height: 18px;
    margin: 18px 0 0 0;
    transition: 0.4s cubic-bezier(0.26, 0.1, 0.6, 0.93);
  }

  .weekDay span {
    color: rgba(150, 150, 150, 0.8);
    font-size: 14px;
  }

  .dayCell {
    height: 48px;
  }

  .dayCell h3 {
    font-size: 16px;
    line-height: 14px;
    padding: 10px 0 0 0;
  }

  .dayCell div {
    font-size: 10px;
    margin-top: 1px;
  }

  .calendarCell h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    padding: 3px 0 0px 0;
    margin: 0;
  }

  .calendarCell div {
    margin: 8px 0 0 0;
    font-size: 10px;
  }
}

/* Toggle */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: auto 0 auto 0;
  opacity: 0.8;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #789ac6;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #3c76c1;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  filter: invert(18%) sepia(0%) saturate(1194%) hue-rotate(152deg)
    brightness(88%) contrast(87%);

  color: rgba(0, 0, 0, 0.5);
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}
